import React from 'react';
import { Link } from 'react-router-dom';

export default function Login({ isLoading, onLogin }) {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    function handleEmailChange(evt) {
        setEmail(evt.target.value)
    }

    function handlePasswordChange(evt) {
        setPassword(evt.target.value)
    }

    function handleSubmit(evt) {
        evt.preventDefault()
        onLogin({ password, email })
    }
    return (
        <>
            <div className="form__container">
                <h3 className="form__title">Вход</h3>
                <form className="form__wrapper form__login" name="form__login" onSubmit={handleSubmit}>
                    <input required onChange={handleEmailChange} className="form__input" type="email" name="email" id="login_email" placeholder="Email" />
                    <input required onChange={handlePasswordChange} className="form__input" type="password" name="password" id="login_password" placeholder="Пароль" />
                    <button type="submit" className="form__button-submit link">{!isLoading ? 'Войти' : 'Выполнение...'}</button>
                </form>
            </div>
            <Link className="form__text link" to='/signup'>Нет аккаунта? Зарегистрироваться</Link>
        </>
    )
}
