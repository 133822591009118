import React from 'react';
import PopupWithForm from "./PopupWithForm";
import { CurrentUserContext } from '../contexts/CurrentUserContext';

export default function EditProfilePopup({ isOpen, onClose, onUpdateUser, isLoading }) {
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const currentUser = React.useContext(CurrentUserContext);

    React.useEffect(() => {
        setName(currentUser.name)
        setDescription(currentUser.about)
    }, [currentUser, isOpen]);

    function handleNameChange(evt) {
        setName(evt.target.value)
    }

    function handleDescriptionChange(evt) {
        setDescription(evt.target.value)
    }

    function handleSubmit(evt) {
        evt.preventDefault()
        onUpdateUser({
            name,
            about: description,
        });
    }

    return (
        <PopupWithForm name='edit-profile' title='Редактировать профиль' buttonName='Сохранить' buttonNameLoading='Сохранение...' isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit} isLoading={isLoading}>
            <div className="popup__input-wrapper">
                <input required onChange={handleNameChange} value={name || ''} className="popup__input" type="text" name="name" id="form_profile_name" placeholder="Имя"
                    minLength="2" maxLength="40" />
                <span className="popup__input-error" id="form_profile_name-error"></span>
            </div>
            <div className="popup__input-wrapper">
                <input required onChange={handleDescriptionChange} value={description || ''} className="popup__input" type="text" name="about" id="form_profile_about"
                    placeholder="Профессиональная деятельность" minLength="2" maxLength="200" />
                <span className="popup__input-error" id="form_profile_about-error"></span>
            </div>
        </PopupWithForm>
    )
}