import React from 'react';
import { Route, Redirect, Switch, useHistory } from 'react-router-dom';
import '../index.css';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import PopupWithForm from './PopupWithForm';
import ImagePopup from './ImagePopup';
import InfoTooltip from './InfoTooltip';
import { api } from '../utils/Api';
import EditProfilePopup from './EditProfilePopup';
import EditAvatarPopup from './EditAvatarPopup';
import AddPlacePopup from './AddPlacePopup';
import ConfirmDeleteCard from './ConfirmDeleteCard';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import Register from './Register';
import Login from './Login';
import ProtectedRoute from './ProtectedRoute';

export default function App() {
  const [isEditProfilePopupOpen, setIsEditProfilePopupOpen] =
    React.useState(false);
  const [isAddPlacePopupOpen, setIsAddPlacePopupOpen] = React.useState(false);
  const [isEditAvatarPopupOpen, setIsEditAvatarPopupOpen] =
    React.useState(false);
  const [isImagePopupOpen, setIsImagePopupOpen] = React.useState(false);
  const [isInfoTooltipOpen, setIsInfoTooltipOpen] = React.useState(false);
  const [isInfoTooltipStatus, setIsInfoTooltipOpenStatus] =
    React.useState(false);
  const [isConfirmDeleteCardOpen, setIsConfirmDeleteCardOpen] =
    React.useState(false);
  const [isDeleteCard, setIsDeleteCard] = React.useState({}); //для временного хранения удаляемой карточки
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState({});
  const [currentUser, setCurrentUser] = React.useState({});
  const [userEmail, setUserEmail] = React.useState('');
  const [cards, setCards] = React.useState([]);
  const isOpen =
    isEditAvatarPopupOpen ||
    isEditProfilePopupOpen ||
    isAddPlacePopupOpen ||
    isConfirmDeleteCardOpen ||
    isImagePopupOpen;
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const history = useHistory(); //навигация

  React.useEffect(() => {
    tokenCheck();
    if (isLoggedIn) {
      history.push('/');
      Promise.all([api.getUser(), api.getInitialCards()])
        .then(([user, cards]) => {
          setCurrentUser(user);
          setCards(cards.reverse());
          setUserEmail(user.email);
        })
        .catch((err) => {
          console.log(err);
        }); 
    }

  }, [history, isLoggedIn]);

  function handleEditProfileClick() {
    setIsEditProfilePopupOpen(true);
  }

  function handleEditAvatarClick() {
    setIsEditAvatarPopupOpen(true);
  }

  function handleAddPlaceClick() {
    setIsAddPlacePopupOpen(true);
  }

  function handleCardClick(card) {
    setIsImagePopupOpen(true);
    setSelectedCard(card);
  }

  function closeAllPopups() {
    setIsEditProfilePopupOpen(false);
    setIsEditAvatarPopupOpen(false);
    setIsAddPlacePopupOpen(false);
    setIsImagePopupOpen(false);
    setSelectedCard({});
    setIsConfirmDeleteCardOpen(false);
    setIsInfoTooltipOpen(false);
  }

  React.useEffect(() => {
    function closeByEscape(evt) {
      if (evt.key === 'Escape') {
        closeAllPopups();
      }
    }

    function OverlayClose(evt) {
      if (evt.target.classList.contains('popup')) {
        closeAllPopups();
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', closeByEscape);
      document.addEventListener('click', OverlayClose);
      return () => {
        document.removeEventListener('keydown', closeByEscape);
        document.removeEventListener('click', OverlayClose);
      };
    }
  }, [isOpen]);

  function handleLikeCard(card) {
    const isLiked = card.likes.some((i) => i === currentUser._id); // изменил с i._id
    if (isLiked) {
      api
        .delLike(card._id)
        .then((newCard) => {
          setCards((state) =>
            state.map((c) => (c._id === card._id ? newCard : c))
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      api
        .setLike(card._id)
        .then((newCard) => {
          setCards((state) =>
            state.map((c) => (c._id === card._id ? newCard : c))
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // удаление карточки из формы подтверждения
  function handleCardDelete() {
    setIsLoading(true);
    api
      .deleteCard(isDeleteCard._id)
      .then(() => {
        setCards((state) => state.filter((c) => c._id !== isDeleteCard._id));
        setIsDeleteCard({});
        closeAllPopups();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  // открытие формы подтверждения удаления карточки
  function handleConfirmCardDelete(card) {
    setIsConfirmDeleteCardOpen(true);
    setIsDeleteCard(card);
  }
  //обновление профиля пользователя
  function handleUpdateUser(user) {
    setIsLoading(true);
    api
      .sendUser(user)
      .then((res) => {
        setCurrentUser(res);
        closeAllPopups();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleOpenInfoTooltip() {
    setIsInfoTooltipOpen(true);
  }

  function handleRegisterUser({ password, email }) {
    setIsLoading(true);
    api
      .signup({
        password: password,
        email: email,
      })
      .then(() => {
        history.push('/signin');
        setIsInfoTooltipOpenStatus(true);
        handleOpenInfoTooltip();
      })
      .catch((err) => {
        setIsInfoTooltipOpenStatus(false);
        handleOpenInfoTooltip();
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleAuthUser({ password, email }) {
    setIsLoading(true);
    api
      .signin({
        password: password,
        email: email,
      })
      .then((res) => {
        setIsLoggedIn(true);
        tokenCheck();
      })
      .catch((err) => {
        setIsInfoTooltipOpenStatus(false);
        handleOpenInfoTooltip();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleLogOutUser() {
    api.logout()
      .then(() => {
        setCurrentUser({});
        setIsLoggedIn(false);
      })
      .catch((err) => {
        console.log(err);
      })
  }
  
  function tokenCheck() {
    api.checkAuthorization()
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  function handleUpdateAvatar(avatar) {
    setIsLoading(true);

    api
      .updateAvatar(avatar.avatar)
      .then((res) => {
        setCurrentUser(res);
        closeAllPopups();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleAddCard(newCard) {
    setIsLoading(true);
    api
      .addCard(newCard)
      .then((res) => {
        setCards([res, ...cards]);
        closeAllPopups();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div className='page'>
        <div className='root'>
          <Header onLogOutUser={handleLogOutUser} userEmail={userEmail} />
          <Switch>
            <ProtectedRoute exact path='/' isLoggedIn={isLoggedIn}>
              <Main
                onEditProfile={handleEditProfileClick}
                onEditAvatar={handleEditAvatarClick}
                onAddPlace={handleAddPlaceClick}
                onCardClick={handleCardClick}
                cards={cards}
                onCardLike={handleLikeCard}
                onCardDelete={handleConfirmCardDelete}
              />
            </ProtectedRoute>
            <Route path='/signup'>
              <Register isLoading={isLoading} onRegister={handleRegisterUser} />
            </Route>
            <Route path='/signin'>
              <Login isLoading={isLoading} onLogin={handleAuthUser} />
            </Route>
            <Route>
              {isLoggedIn ? <Redirect to='/' /> : <Redirect to='/signin' />}
            </Route>
          </Switch>
          <Footer />
          <EditProfilePopup
            isOpen={isEditProfilePopupOpen}
            onClose={closeAllPopups}
            onUpdateUser={handleUpdateUser}
            isLoading={isLoading}
          />
          <EditAvatarPopup
            isOpen={isEditAvatarPopupOpen}
            onClose={closeAllPopups}
            onUpdateAvatar={handleUpdateAvatar}
            isLoading={isLoading}
          />
          <AddPlacePopup
            isOpen={isAddPlacePopupOpen}
            onClose={closeAllPopups}
            onAddCard={handleAddCard}
            isLoading={isLoading}
          />
          <ConfirmDeleteCard
            isOpen={isConfirmDeleteCardOpen}
            onClose={closeAllPopups}
            onDeleteCard={handleCardDelete}
            isLoading={isLoading}
          />
          <PopupWithForm
            name='card-delete'
            title='Вы уверены?'
            buttonName='Да'
            onClose={closeAllPopups}
          />
          <ImagePopup
            card={selectedCard}
            isOpen={isImagePopupOpen}
            onClose={closeAllPopups}
          />
          <InfoTooltip
            status={isInfoTooltipStatus}
            name='infoTooltip'
            isOpen={isInfoTooltipOpen}
            onClose={closeAllPopups}
          />
        </div>
      </div>
    </CurrentUserContext.Provider>
  );
}
