export default function PopupWithForm({name, title, isOpen, onClose, buttonName, children, onSubmit, buttonNameLoading, isLoading}) {
    return (
        <div className={`popup ${isOpen && 'popup_opened'}`} id={`popup__${name}`}>
            <div className="popup__container">
                <h3 className="popup__title">{title}</h3>
                <form className={`popup__form popup__form_${name}`} name={`popup__${name}`} onSubmit={onSubmit}>
                    {children}
                    <button className="popup__button-save link" type="submit">{!isLoading ? buttonName : buttonNameLoading}</button>
                </form>
                <button onClick={onClose} type="button" className="popup__button-close link"></button>
            </div>
        </div>
    )
}