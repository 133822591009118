import imgError from '../images/error.svg';
import imgSuccess from '../images/success.svg';

export default function InfoTooltip({ name, status, isOpen, onClose }) {

    return (
        <div className={`popup ${isOpen ? 'popup_opened' : ''}`} id={`popup__${name}`}>
            <div className="popup__container">
                <img className="popup__image-register" src={status ? imgSuccess : imgError} alt={status ? 'успешно' : 'ошибка'} />
                <h3 className="popup__title popup_title_type_infotooltip">{status ? 'Вы успешно зарегистрировались!' : 'Что-то пошло не так! Попробуйте ещё раз.'}</h3>
                <button onClick={onClose} type="button" className="popup__button-close link"></button>
            </div>
        </div>
    )
}