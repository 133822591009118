import React from 'react';
import PopupWithForm from "./PopupWithForm";

export default function ConfirmDeleteCard({ isOpen, onClose, isLoading, onDeleteCard }) {

    function handleSubmit(evt) {
        evt.preventDefault()
        onDeleteCard()
    }

    return (
        <PopupWithForm name='edit-profile' title='Вы уверены?' buttonName='Да' buttonNameLoading='Удаление...' isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit} isLoading={isLoading} />
    )
}