import React from 'react';
import { Link } from 'react-router-dom';

export default function Login({ isLoading, onRegister }) {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    function handleEmailChange(evt) {
        setEmail(evt.target.value)
    }

    function handlePasswordChange(evt) {
        setPassword(evt.target.value)
    }

    function handleSubmit(evt) {
        evt.preventDefault()
        onRegister({ password, email });
    }

    return (
        <>
            <div className="form__container">
                <h3 className="form__title">Регистрация</h3>
                <form className="form__wrapper form__register" name="form__register" onSubmit={handleSubmit}>
                    <input required onChange={handleEmailChange} className="form__input" type="email" name="email" id="login_email" placeholder="Email" />
                    <input required onChange={handlePasswordChange} className="form__input" type="password" name="password" id="login_password" placeholder="Пароль" />
                    <button type="submit" className="form__button-submit link">{!isLoading ? 'Зарегестрироваться' : 'Сохранение...'}</button>
                </form>
            </div>
            <Link className="form__text link" to='/signin'>Уже зарегистрированы? Войти</Link>
        </>
    )
}