import PopupWithForm from "./PopupWithForm";
import React from 'react';

export default function EditAvatarPopup({ isOpen, onClose, onUpdateAvatar, isLoading }) {
    const avatarRef = React.useRef('');
    
    React.useEffect(() => {
        avatarRef.current.value = ""
    }, [isOpen]);

    function handleSubmit(evt) {
        evt.preventDefault()

        onUpdateAvatar({
            avatar: avatarRef.current.value,
          });
    }

    return (
        <PopupWithForm name='edit-avatar' title='Обновить аватар' buttonName='Сохранить' buttonNameLoading='Сохранение...' isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit} isLoading={isLoading}>
            <div className="popup__input-wrapper">
                <input required ref={avatarRef} className="popup__input" type="url" name="link" id="form_avatar_url"
                    placeholder="Ссылка на аватар" />
                <span className="popup__input-error" id="form_avatar_url-error"></span>
            </div>
        </PopupWithForm>
    )
}