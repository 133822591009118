export default function ImagePopup({isOpen, card, onClose }) {
    return (
        <div className={`popup popup_type_card-image ${isOpen && 'popup_opened'}`}> 
            <div className="popup__container-image">
                <figure className="popup__figure">
                    <img className="popup__image" src={card.link} alt={card.name} />
                    <figcaption className="popup__figcaption">{card.name}</figcaption>
                </figure>
                <button onClick={onClose} type="button" className="popup__button-close link"></button>
            </div>
        </div>
    )
}