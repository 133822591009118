import React from 'react';
import { CurrentUserContext } from '../contexts/CurrentUserContext';

export default function Card({ card, onCardClick, onCardLike, onCardDelete }) {
    const currentUser = React.useContext(CurrentUserContext);
    //для определения чужой и своей карточки
    // console.log('card.owner._id -->> ', card.owner);
    // console.log('currentUser._id -->> ', currentUser._id);

    const isOwn = card.owner === currentUser._id
    //для определения есть ли лайк у карточки
    const isLiked = card.likes.some(i => i === currentUser._id); // изменил с i._id на i

    const cardLikeButtonClassName = (
        `elements__like ${isLiked ? 'elements__like_active' : ''}`
    );

    function handleClick() {
        onCardClick(card);
    }

    function handleLikeClick() {
        onCardLike(card);
    }

    function handleCardDelete() {
        onCardDelete(card);
    }

    return (
        <article className="elements__card">
            <button onClick={handleCardDelete} type="button" style={!isOwn ? { display: 'none' } : {}} className="elements__basket"></button>
            <img onClick={handleClick} className="elements__figure link" src={card.link} alt={card.name} />
            <div className="elements__description">
                <h2 className="elements__caption">{card.name}</h2>
                <div className="elements__group">
                    <button onClick={handleLikeClick} type="button" className={cardLikeButtonClassName}></button>
                    <p className="elements__counter">{card.likes.length}</p>
                </div>
            </div>
        </article>
    )
}