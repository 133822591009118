import logoPath from '../images/logo.png';
import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';

export default function Header({ onLogOutUser, userEmail }) {
  return (
    <header className="header">
      <img className="logo logo_place_header" src={logoPath} alt="Место" />
      <Switch>
        <Route path="/signin">
          <Link className="header__link link" to='/signup'>Регистрация</Link>
        </Route>
        <Route path="/signup">
          <Link className="header__link link" to='/signin'>Войти</Link>
        </Route>
        <Route path="/">
          <div className='header__container-text'>
            <p className="header__text">{userEmail}</p>
            <button className='header__logout link' onClick={onLogOutUser}>Выйти</button>
          </div>
        </Route>
      </Switch>
    </header>
  )
}