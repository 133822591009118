class Api {
    constructor({ baseUrl, headers }) {
        this._baseUrl = baseUrl
        this._headers = headers
    }
    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        }
        return Promise.reject(`Ошибка ${res.status}`)
    }

    checkAuthorization() {  // проверка авторизации пользователя при заходе на сайт
        return fetch(`${this._baseUrl}`, {
            headers: this._headers,
            credentials: 'include',
        })
            .then(this._checkResponse)
    }

    signup(obj) { //регистрация пользователя
        return fetch(`${this._baseUrl}signup`, {
            method: 'POST',
            headers: this._headers,
            body: JSON.stringify({
                password: obj.password,
                email: obj.email
            }),
            credentials: 'include',
        })
            .then(this._checkResponse)
    }

    signin(obj) { //авторизация пользователя
        return fetch(`${this._baseUrl}signin`, {
            method: 'POST',
            headers: this._headers,
            body: JSON.stringify({
                password: obj.password,
                email: obj.email
            }),
            credentials: 'include',
        })
            .then(this._checkResponse)
    }

    logout() {
        return fetch(`${this._baseUrl}logout`, {
            headers: this._headers,
            credentials: 'include',
        })
            .then(this._checkResponse)
    }

    getInitialCards() {  // загрузка карточек с сервера
        return fetch(`${this._baseUrl}cards`, {
            headers: this._headers,
            credentials: 'include',
        })
            .then(this._checkResponse)
    }

    getUser() {  //загрузка данных о пользователе с сервера
        return fetch(`${this._baseUrl}users/me`, {
            headers: this._headers,
            credentials: 'include',
        })
            .then(this._checkResponse)
    }

    sendUser(user) {  //отправка на сервер новых данных пользователя
        return fetch(`${this._baseUrl}users/me`, {
            method: 'PATCH',
            headers: this._headers,
            body: JSON.stringify({
                name: user.name,
                about: user.about
            }),
            credentials: 'include',
        })
            .then(this._checkResponse)
    }

    updateAvatar(sendAvatar) { //обновление аватара
        return fetch(`${this._baseUrl}users/me/avatar`, {
            method: 'PATCH',
            headers: this._headers,
            body: JSON.stringify({
                avatar: sendAvatar
            }),
            credentials: 'include',
        })
            .then(this._checkResponse)
    }

    addCard(obj) {  //отправка карточки на сервер
        return fetch(`${this._baseUrl}cards`, {
            method: 'POST',
            headers: this._headers,
            body: JSON.stringify({
                name: obj.name,
                link: obj.link
            }),
            credentials: 'include',
        })
            .then(this._checkResponse)
    }

    deleteCard(id) {  //удаление карточки на сервере
        return fetch(`${this._baseUrl}cards/${id}`, {
            method: 'DELETE',
            headers: this._headers,
            credentials: 'include',
        })
            .then(this._checkResponse)
    }

    setLike(idCard) { // установка лайка
        return fetch(`${this._baseUrl}cards/${idCard}/likes`, {
            method: 'PUT',
            headers: this._headers,
            credentials: 'include',
        })
            .then(this._checkResponse)
    }

    delLike(idCard) {  //удаление лайка
        return fetch(`${this._baseUrl}cards/${idCard}/likes`, {
            method: 'DELETE',
            headers: this._headers,
            credentials: 'include',
        })
            .then(this._checkResponse)
    }
}

export const api = new Api({
    baseUrl: `${window.location.protocol}${process.env.REACT_APP_API_URL || '//localhost:3001'}/`,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
});
