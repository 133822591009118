import React from "react";
import PopupWithForm from "./PopupWithForm"
export default function AddPlacePopup({ isOpen, onClose, onAddCard, isLoading }) {
  const [cardTitle, setCardTitle] = React.useState('');
  const [cardLink, setCardLink] = React.useState('');

  React.useEffect(() => {
    setCardTitle("")
    setCardLink("")
  }, [isOpen]);

  function handleCardTitleChange(evt) {
    setCardTitle(evt.target.value)
  }

  function handleCardLinkChange(evt) {
    setCardLink(evt.target.value)
  }

  function handleSubmit(evt) {
    evt.preventDefault()

    onAddCard({
      name: cardTitle,
      link: cardLink
    });
  }

  return (
    <PopupWithForm name='add-card' title='Новое место' buttonName='Создать' buttonNameLoading='Создание...' isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit} isLoading={isLoading}>
      <div className="popup__input-wrapper">
        <input required value={cardTitle || ''} onChange={handleCardTitleChange} className="popup__input" type="text" name="name" id="form_card__name" placeholder="Название"
          minLength="2" maxLength="30" />
        <span className="popup__input-error" id="form_card__name-error"></span>
      </div>
      <div className="popup__input-wrapper">
        <input required value={cardLink || ''} onChange={handleCardLinkChange} className="popup__input" type="url" name="link" id="form_card_url"
          placeholder="Ссылка на картинку" />
        <span className="popup__input-error" id="form_card_url-error"></span>
      </div>
    </PopupWithForm>
  )
}